import * as React from "react"
import Menu from '../../components/menu'
import Footer from '../../components/footer'
import styled from 'styled-components'
import StyledLink from '../../components/styledLink'
import '../../styles/typography.css'
import SubPageHero from "../../components/sub-page-hero"
import squiggles from '../../images/squiggles.svg'
import clock from '../../images/clock-icon.svg'
import euro  from '../../images/euro-icon.svg'
import HelmetInfo from '../../components/helmet-info'
import 'animate.css'

const SquigglesImage = () => {
    return <img className="hero-squiggles" src={squiggles} alt="decorative squiggles"/>
}

const ServiceWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;

    h2, p {
        text-align: left;
    }
    .inlineLink { 
        margin-left: 20px;
        margin-top: 10px;
    }
  
    @media screen and (min-width: 900px) {
        grid-template-columns: 1fr 1fr;
    }
`

const ServiceList = () => {
    return(
        <ServiceWrapper>
            
 
            <div className="service">
                <h2>What are explainer videos and why use them?</h2>
                <p>Explainer videos (also called homepage videos, overview videos or conversion videos) are designed to explain your product, business, service in a way that your audience can easily understand in a short period of time. Usually this is 2 minutes or less. </p>
                <p>Most explainer videos cover four key points:</p>
                <ol>
                    <li>the problem</li>
                    <li>the solution</li>
                    <li>the benefits</li>
                    <li>how it works</li>
                </ol>       
                <p>A quality explainer video is short and engaging, while still conveying the desired information. No matter what you are explaining, an explainer video should always leave your viewers with a clear understanding of what you do and a desire to take the next step.</p>
            </div>
            <div className="service">
                <h2>When and how should I use an explainer video?</h2>
                <p>Explainer videos are often used towards the top of the sales funnel. They are useful when a potential customer is aware of their problem or need and start to look for potential solutions. Explainer videos can be used almost anywhere: homepages, product or service pages, newsletters, trade shows, and sales meetings.</p>
                <StyledLink className='inlineLink' to={'../../contact-us'}>Get in touch</StyledLink>
                <SquigglesImage/>

        </div>
    </ServiceWrapper>
    )
}

const ServicePriceListWrapper = styled.div`
    background: #e6e6e6;
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 20px;
`

const ServicePriceListGrid = styled.div`
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 20px;

    @media screen and (min-width: 900px) {
        grid-template-columns: 1fr 1fr;
    }

    .prices-icon {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        @media screen and (min-width: 900px) {
            grid-template-columns: 146px 1fr;
        }

        img {
            max-width: 146px;
            margin: 0 20px 20px 20px;

            @media screen and (min-width: 900px) {
                margin: 0;
            }
         
        }
        
        p { 
            text-align: left; 
        }

        h2 {
            text-align: left; 
            font-size: 2rem;
        }
    }
`

const ServicePriceListItem = ({image, title, text}) => {
  return(
    <div className="prices-icon">
        <img src={image} alt=""/>
        <div className="prices-info">
            <h2>{title}</h2> 
            <p>{text}</p>
        </div>
    </div>
  )   
}

const ServicePriceList = () => {
    return (
    <>

        <ServicePriceListWrapper>
            <ServicePriceListGrid>

                <ServicePriceListItem 
                    image={clock} 
                    title={'How long does and explainer video take to produce?'}
                    text={'Explainer videos normally take between 6-8 weeks to produce. If clients have extensive tweaks or changes it can take up to 12 weeks.'}
                />

                <ServicePriceListItem 
                    image={euro} 
                    title={'How much does and explainer video cost?'}
                    text={'Explainer videos generally cost between €4,000 and €8,000. Though the budget can vary depending on the project.'}
                />

            </ServicePriceListGrid>
        </ServicePriceListWrapper>

        <FullWidth>
            <StyledLink to="../">Our Services</StyledLink>  
        </FullWidth>
    </>

        
    )
}

const FullWidth = styled.div`
    background-color: black;
    padding: 50px;
    padding-top: 10px;
`

const Shorts = () => {
    return(
        <>
        <HelmetInfo />
        <Menu/>
        <SubPageHero>
            <h1 className="animate__animated animate__bounceInDown">Animated Shorts</h1>
            <img className="hero-squiggles" src={squiggles} alt="decorative squiggles" />
        </SubPageHero>
        <ServiceList />
        <ServicePriceList />
       
        <Footer />
        </>
    )
}

export default Shorts